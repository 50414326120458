<template>
  <div class="box">
    <div class="title">
      <h1 class="name">竞拍流程</h1>
      <div class="ico" />
      <div class="english">Auction process</div>
    </div>
    <div class="content">
      <div class="nums">
        <div>01</div>
        <div>02</div>
        <div>03</div>
        <div>04</div>
        <div>05</div> 
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="1">
          <div slot="label">   <SvgIcon iconClass="rzsf"/> 认证身份</div>
          <div class="jieshao">
            <div class="left">
              <img src="../images/brand_1.png" style="width: 100%" alt="" />
            </div>
            <div class="right">
              <h2>认证身份</h2>
              <p>参与竞拍前，首先必须在平台中完成账户注册。</p>
              <p>
                注册完成，通过注册账号登录后，还须创建或加入企业，并以企业成员身份，代表企业参与具体场次的报名和竞拍活动。同一场竞拍活动中，企业至多可允许一个成员账户进行报名和参拍。非报名账号不可进入场次进行拍卖活动。
              </p>
              <p>个人用户目前暂不可报名参与竞拍活动。</p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="2">
          <div slot="label">   <SvgIcon iconClass="bmjb"/> 报名交保</div>
          <div class="jieshao">
            <div class="left">
              <img src="../images/brand_2.png" style="width: 100%" alt="" />
            </div>
            <div class="right">
              <h2>报名交保</h2>
              <p>
                报名时，务必阅读竞拍标的介绍信息，确认交易规则和竞拍条款说明。确认后发起保证金冻结。若可用余额充足，则系统自动完成资金冻结，并在竞拍流程结束后自动释放。冻结金额的信息可在企业钱包中查看。若余额不足，可先前往钱包进行充值，充值完成后再进行报名操作。
              </p>
              <p>
                报名在竞拍活动开始前可随时取消，被冻结的保证金也随即进行释放。
              </p>
              <p>
                若企业报名参加同一批顺次多场竞拍活动，可统一缴纳一份保证金并顺次参加同批次所有竞拍活动。
              </p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="3">
          <div slot="label">   <SvgIcon iconClass="xxkc"/> 线下考察</div>
          <div class="jieshao">
            <div class="left">
              <img src="../images/brand_3.png" style="width: 80%" alt="" />
            </div>
            <div class="right">
              <h2>线下考察</h2>
              <p>
                竞买人报名拍卖场次成功后，可在指定时间段内联系对接人安排线下看车，如在指定时间内未申请，默认无需线下看车，对车辆状态无异议
              </p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="4">
          <div slot="label">   <SvgIcon iconClass="xsjp"/> 线上竞拍</div>
          <div class="jieshao">
            <div class="left">
              <img src="../images/brand_4.png" style="width: 100%" alt="" />
            </div>
            <div class="right">
              <h2>线上竞拍</h2>
              <p>
                在申请参拍，并缴纳完相应额度保证金后，平台会自动发送拍卖号牌，用户进行申领，申领成功后可以参拍报名场次
              </p>
              <p>
                已经领号成功的用户，拍卖会开始后，用户可在拍卖项目中参与本场拍卖会，在拍卖时间段内，用户可进行拍卖行详情页进行出价竞拍操作
              </p>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane name="5">
          <div slot="label">   <SvgIcon iconClass="fkqy"/> 付款签约</div>
          <div class="jieshao">
            <div class="left">
              <img src="../images/brand_5.png" style="width: 100%" alt="" />
            </div>
            <div class="right">
              <h2>付款签约</h2>
              <p>
                竞拍场次结束并且状态显示为“成交”后，平台会向成功竞拍人和上拍方发送电子合同进行签约
              </p>
              <p>
                买卖双方需要在平台发送电子合同后24小时内完成签约，并在签约完成48小时内缴纳竞拍服务费，双方按合同约定进行付款和交割。
              </p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "1",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style lang="less">
.box {
  width: 100%;
  height: 800px;
  background: url("../../../assets/images/home/jingPaiBG.png");
  background-size: cover;
  padding-top: 80px;
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ico {
      width: 36px;
      height: 4px;
      background-color: #0dac65;
      margin: 16px 0;
    }
    .name {
      font-size: 42px;
      color: rgba(0, 0, 0, 0.85);
    }
    .english {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
    }
  }
  .content {
    width: 1200px;
    margin: 10vh auto 0;
    position: relative;
    .nums {
      position: absolute;
      top: -75px;
      left:-80px ;
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        padding: 0 78px;
        font-size: 100px;
        font-weight: 700;
        background: linear-gradient(
          to bottom,
          #ccc,
          #f6f6f800
        ); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
        -webkit-background-clip: text; /*将设置的背景颜色限制在文字中*/
        -webkit-text-fill-color: transparent; /*给文字设置成透明*/
      }
    }
    .el-tabs__item {
      padding: 0 83.5px;
      font-size: 18px;
      color: #666;
    }
    .el-tabs__item.is-active{
      color: #0dac65;
      font-weight: 600;
    }
    .jieshao {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 6vh;
      .left {
        width: 47%;
      }
      .right {
        width: 49%;
        h2 {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 20px;
        }
        p {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 20px;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
