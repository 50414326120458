<template>
  <div class="service">
    <div class="title">
      <h1 class="name">优质服务</h1>
      <div class="ico" />
      <div class="english">Quality service</div>
    </div>
    <div class="content">
      <div class="piclists">
        <div
          class="item"
          v-for="(item, index) in piclist"
          :key="index"
          :style="{
            backgroundImage: 'url(' + item.bg + ')',
          }"
        >
          <img :src="item.img" style="width: 54px" alt="" />
          <div class="name">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      piclist: [
        {
          title: "资质完整  安全合规",
          bg: require("@/assets/images/home/Solution1.png"),
          img: require("@/assets/images/home/service1.png"),
        },
        {
          title: "专人服务  全程指导",
          bg: require("@/assets/images/home/Solution2.png"),
          img: require("@/assets/images/home/service2.png"),
        },
        {
          title: "拍卖数据  实时掌握",
          bg: require("@/assets/images/home/Solution3.png"),
          img: require("@/assets/images/home/service3.png"),
        },
        {
          title: "在线合同  履约保障",
          bg: require("@/assets/images/home/Solution4.png"),
          img: require("@/assets/images/home/service4.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.service {
  width: 100%;
  height: 800px;
  padding-top: 80px;
  .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ico {
      width: 36px;
      height: 4px;
      background-color: #0dac65;
      margin: 16px 0;
    }
    .name {
      font-size: 42px;
      color: rgba(0, 0, 0, 0.85);
    }
    .english {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.2);
    }
  }
  .content {
    width: 1200px;
    margin: 10vh auto 0;
    .piclists {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        box-sizing: border-box;
        padding-left: 60px;
        width: 49%;
        height: 200px;
        display: flex;
        align-items: center;
        background-size: cover;
        margin-bottom: 16px;
        .name {
          margin-left: 20px;
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }
}
</style>
