<template>
  <div>
    <el-carousel height="540px">
      <el-carousel-item v-for="item in bannerList" :key="item.imageObjectName">
        <el-image
          :src="item.imageObjectName"
          style="width: 100%; height: 100%; cursor: pointer"
          @click="link(item.imageTargetUrl)"
          fit="cover"
        />
      </el-carousel-item>
    </el-carousel>
    <ListContainer />
    <Recommend />
    <Solution />
  </div>
</template>
<script>
//引入子组件
//发现三级联动在search模块中也使用，咱们将三级联动的组件注册为全局组件
//下面引入的是局部组件：定义、引入、注册、使用
import ListContainer from "@/pages/Home/ListContainer";
import Recommend from "@/pages/Home/Recommend";
import Solution from "@/pages/Home/Solution";
import { getBanners } from "@/api/index.js";

export default {
  name: "Home",
  components: {
    ListContainer,
    Recommend,
    Solution,
  },
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getBanners().then(({ code, data }) => {
        if (code == 200) {
          this.bannerList = data;
          this.bannerList.forEach((i) => {
            i.imageObjectName =
              "/api/file/displayResource?objectName=" + i.imageObjectName;
          });
        }
      });
    },
    link(url) {
      if(!url) return
      window.open(url);
    },
  },
};
</script>

<style scoped></style>
